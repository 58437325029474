<!-- 展示个人信息My的页面，常规模板使用cell，展示用户头像，昵称；订单模块；使用帮助；隐私政策登 -->
<template>
  <nut-cell-group>
    <nut-avatar size="large"><img :src="user.avatar" />星乐思</nut-avatar>
    <nut-cell title="账号" :desc="user.username"></nut-cell>
  </nut-cell-group>
  <nut-cell-group>
    <!-- <nut-cell title="学生资料" is-link to="/orderList"></nut-cell> -->
    <nut-cell title="报读课程" is-link to="/selectClass"></nut-cell>
    <nut-cell title="上课记录" is-link to="/classNotify"></nut-cell>
    <nut-cell title="课消记录" is-link to="/classDeleteRecord"></nut-cell>
    <nut-cell title="消费记录" is-link to="/orderList"></nut-cell>
    <nut-cell title="使用帮助" is-link></nut-cell>
    <nut-cell title="隐私政策" is-link></nut-cell>
  </nut-cell-group>
</template>
<script>
import { ref } from 'vue';
const user = ref({
  avatar: 'https://img.starlake.tech/pic/ab4d72df489dc3d1cdd4b6887439d643.png',
  username: '星乐思'
});

export default {
  name: 'My',
  components: {
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
// 获取sessionStorage保存的用户信息
    getUserInfo() {
      user.value.username = window.sessionStorage.getItem('username')
  },
},
  setup() {
    return {
      user
    };
  }
};
</script>